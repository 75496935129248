<template>
    <Estimate :key="orderId" :isQuoting="true" type="customer" :headerData="headerData" area="my-estimates" />
</template>

<script>
import { computed } from 'vue'
import {  useRoute } from 'vue-router'
import Estimate from '@/components/item-wizard/estimate.vue'
export default ({
    components: {
        Estimate,
    },
    setup() {
        const route = useRoute()

        const headerData = {
            backLink: {
                url: '/my-estimates',
                title: 'Back to MY ESTIMATES'
            },
            title: 'Estimate',
            requestBtn: {
                title: 'Send Estimate',
            },
            totalBtn: {
                title: 'ESTIMATE TOTAL:'
            }
        }

        const orderId = computed(() => route.params.id )

        return {
            headerData,
            orderId,
        }
        
    },
})
</script>
