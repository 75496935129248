// import store from "../store/store";
// import {httpReq} from "../htttpReq";

export function isOpeningIsThePreferToEnter() {

  if (localStorage.getItem('size_entering_type')) return localStorage.getItem('size_entering_type') === 'Opening'
  // return upAndGetStorageData().size_entering_type === 'Opening'

}

export function isWidthIsTheSizeToEnterFirst() {

  if (localStorage.getItem('prefer_entries')) return localStorage.getItem('prefer_entries') === 'width'
  // return upAndGetStorageData().prefer_entries === 'width'

}

// function upAndGetStorageData() {
//   store.commit('preloader/preloader', true);
//   httpReq.get('user/update-account')
//     .then(response => {

//       store.commit('account/updatePrefer', response.data.prefer_entries);
//       store.commit('account/updateSizeEntering', response.data.size_entering_type);
//       store.commit('account/userInfo', response.data);
//     })
//     .catch((error) => {
//       if (error.response.status == '401') {
//         localStorage.removeItem('user-token');
//         localStorage.removeItem('nameUser');
//         window.location = '/login';
//       }
//     })
//     .finally(() => {
//       store.commit('preloader/preloader', false);
//     })

//   return {
//     size_entering_type: store.getters['account/sizeEnteringType'],
//     prefer_entries: store.getters['account/preferEntries'],
//   }

// }
