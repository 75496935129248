<template>
    <RequestTemplate
        title="Where do you need the job done?"
        desc="To provide you with an accurate estimate and ensure we service your area, please provide the job address where the screens will be installed."
    >
        <FormAddress
            class="form-address"
            :states="states"
            :data="addressData"
            @updAddress="updAddress($event)"
            @updStates="states = $event"
            @onClick="setNewAddress"
            :showAddressError="showAddressError"
            @hideError="showAddressError = false"
        />
        <div v-if="addressData.btn" class="tw-sticky tw-bottom-0 tw-w-full tw-z-10">
            <Button class="tw-w-full" v-if="addressData.btn" :data="addressData.btn" @click="setNewAddress"/>
        </div>
    </RequestTemplate>
</template>

<script>
import FormAddress from '../../form/FormAddress.vue'
import api from '../../../services/request.service'
import RequestTemplate from './RequestTemplate.vue'
import { nextTick, reactive, ref, watch } from 'vue'
import Button from '../../form/Button.vue'

export default ({
    props: ['id', 'data'],
    components: {
        FormAddress,
        RequestTemplate,
        Button,
    },
    setup(props, { emit }) {
        const payload = ref({})
        const states = ref(null)
        const showAddressError = ref(false)

        const addressData = reactive({
            inputs: [
                { label: 'Address', component: 'address-autocomplete', placeholder: 'Enter street address', value: props.data.customer_address?.address, key: 'address', type: 'text', style: 'width: 100%;' ,required:true},
                { label: 'State', component: 'address-state', placeholder: 'Enter state', value: '', value_id: props.data.customer_address?.state,  key: 'state', type: 'text', style: 'width: 100%;',required:true },
                { label: 'Suburb', placeholder: 'Enter suburb', value: props.data.customer_address?.suburb,  key: 'suburb', type: 'text',style: 'width: calc(65% - 12px); margin-right: 12px;',required:true },
                { label: 'Postcode', placeholder: 'Enter postcode', value: props.data.customer_address?.postcode,  key: 'postcode', type: 'text', style: 'width: 35%;' ,required:true},
                { label: 'Mobile', mask: '#### ### ###', placeholder: 'Enter mobile', value: props.data.quoting_portal_user?.mobile,  key: 'mobile', type: 'text', style: 'width: 100%;' ,required:true, sanitize: (val) => val.replaceAll(' ', '')},
                { label: 'Email', placeholder: 'Email', value: props.data.quoting_portal_user?.email,  key: 'email', type: 'email', style: 'width: 100%;' ,required:true, disabled: true},
            ],
            btn: { type: 'primary', title: 'Continue', icon: 'fas fa-check', loading: false },
        })

        watch(states, (val) => {
            if(val){
                updAddress({
                'address': props.data.customer_address?.address,
                'state': props.data.customer_address?.state,
                'suburb': props.data.customer_address?.suburb,
                'postcode': props.data.customer_address?.postcode
            })
            }
        }, {once: false, immediate: true})

            
        updAddress({
            'address': props.data.customer_address?.address,
            'state': props.data.customer_address?.state,
            'suburb': props.data.customer_address?.suburb,
            'postcode': props.data.customer_address?.postcode
        })

        async function updAddress(address) {

          showAddressError.value = false
            for(const key in address) {
                const value = address[key]
                const input = addressData.inputs.find(el => el.key == key)
                if(input && key !== 'state') input.value = value
                else if(input) {
                    let matchStateName = states.value.find(el => el.name == address[key])
                    if(!matchStateName) {
                        matchStateName = states.value.find(el => el.state_id == address[key])
                    }

                    if(matchStateName) {
                        input.value = null
                        input.value_id = null
                        nextTick(() => {
                            input.value = matchStateName.name
                            input.value_id = matchStateName.state_id
                        })
                    }else {
                        if(key === 'state') {
                            input.value = null
                            input.value_id = null
                        }
                    }
                }
                if(value === '' || value) payload.value[key] = value
            }
        }

        async function setNewAddress() {
            showAddressError.value = false
            const form = JSON.parse(JSON.stringify(payload.value))
            //set value id
            const stateInput = addressData.inputs.find(el => el.key == 'state')
            if(form.state) form.state = stateInput.value_id

            if(!form.address || !form.postcode || !form.state || !form.suburb){
              showAddressError.value = !form.address ? 'Address field is required': !form.state ? 'State field is required': !form.suburb ? 'Suburb field is required': !form.postcode ? 'Postcode field is required': false
              addressData.btn.loading = false
              return
            }
            const result = await api.sendNewAddress({ id: props.id, ...form })
            addressData.btn.loading = false
            if(result.success) emit('close', 'updHeaderData')

        }

        return {
            addressData,
            updAddress,
            setNewAddress,
            states,
            showAddressError
        }
    },


})
</script>

<style scoped lang="scss">
.form-address {
    margin-right: auto;
    width: 100%;
    ::v-deep .btn {
        width: 100%;
    }
}
</style>
